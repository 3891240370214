@font-face {
  font-family: "HC";
  src: url("../fonts/HC.eot");
  src: url("../fonts/HC.eot?#iefix") format("embedded-opentype"), url("../fonts/HC.woff") format("woff"), url("../fonts/HC.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "HC-bold";
  src: url("../fonts/HC-bold.eot");
  src: url("../fonts/HC-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/HC-bold.woff") format("woff"), url("../fonts/HC-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* base styles*/
*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

html {
  height: 100%;
  font-family: "HC", sans-serif;
  font-size: 62.5%;
  font-weight: normal;
  font-style: normal;
}

body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: 1.6rem;
  line-height: 1;
  position: relative;
}

/* /base styles */
/* styles for wp-admin panel */
body.customize-support .wp-panel-show {
  top: 32px;
}

@media screen and (max-width: 782px) {
  body.customize-support .wp-panel-show {
    top: 46px;
  }
  #wpadminbar {
    position: fixed !important;
  }
}

/* /styles for wp-admin panel */
/*End Layout*/
